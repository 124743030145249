import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Button = ({
  className,
  href,
  outboundLink,
  modal,
  onClick,
  type,
  altStyle,
  icon,
  text,
}) => {
  const Tag =
    href && href.includes("#") ? AnchorLink : href ? AniLink : "button";
  const target = outboundLink && "_blank";
  const rel = outboundLink && "noopener noreferrer";
  let link = true;
  let anchor = false;

  href && (link = !href.includes("tel:") && !href.includes("mailto:"));

  return (
    <Tag
      className={`group inline-flex items-center justify-center space-x-2.5 font-bold no-underline
       ${
         altStyle === 2
           ? "text-primary-900 hover:text-primary-500"
           : altStyle === 3
           ? "text-primary-900 hover:text-primary-800"
           : "text-primary-900 hover:text-green-400"
       } ${className}`}
      {...(anchor
        ? { to: href }
        : link
        ? { fade: "true", to: href }
        : { href: href })}
      target={target}
      rel={rel}
      data-modal-open={modal}
      onClick={onClick}
      type={type}
      alt-style={altStyle}
    >
      <div>{text}</div>
      <div
        className={`flex h-8 min-w-[32px] items-center justify-center rounded-full transition-all duration-300 ease-linear ${
          altStyle === 2
            ? `bg-primary-500 group-hover:bg-primary-700`
            : altStyle === 3
            ? `bg-primary-800 group-hover:bg-primary-900`
            : `bg-green-400 group-hover:bg-green-400`
        }`}
      >
        <i
          className={`${icon || "fa-solid fa-arrow-right"} text-sm text-white`}
        ></i>
      </div>
    </Tag>
  );
};

export default Button;
