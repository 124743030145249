import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Industries from "../components/Repeating/Industries";
import Testimonials from "../components/Repeating/Testimonials";
import FAQs from "../components/Repeating/FAQs";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
    const faqs = [
        {
          question:
            "What is a Fractional CFO?",
          answer: (
            <>
              <p className="mb-0">
              Fractional CFOs provide the highest level of strategic advisory for business clients.
They help businesses where they need help the most, which can range from cash flow management to forecasting to budget creation & reporting to providing investors with financial reporting and everything in between. They are your dedicated finance resource to support the growth and strategy of your enterprise. As the name implies, they are your company’s CFO at a fraction of the cost of a full-time hire. 

              </p>
            </>
          ),
        },
        {
          question: "Does my cannabis business need a Fractional CFO?",
          answer: (
            <>
              <p className="mb-0">
              With all the hoopla surrounding medical, recreational, 280E, legalization, etc., what gets lost in the shuffle sometimes is you’re still trying to run a successful business. At Dark Horse CPAs, we view your cannabis business as just that – a business. A Dark Horse CPA Fractional CFO can help to take your business to the next level and set your business apart from the competition.
              </p>
            </>
          ),
        },
        {
          question:
            "Does my business need to file more than income taxes?",
          answer: (
            <>
              <p className="mb-0">
              Quite possibly. Most states require sales taxes, plus recreational or medical cannabis sales and excise taxes. Dark Horse CPAs will assist you in meeting all your tax filing requirements.
              </p>
            </>
          ),
        },
        {
          question:
            "Do you do proforma projections and financial forecasting?",
          answer: (
            <>
              <p className="mb-0">
              Yes, we help our clients with financial modeling & forecasting which includes proformas, budgets, and cash flow forecasts. Beyond that, we’ll work with you to build a growth plan to achieve (and hopefully beat) these projections. We also offer interactive financial dashboards to assist you in running your business.
              </p>
            </>
          ),
        },
        {
            question:
              "Do you help manage and optimize cash flow?",
            answer: (
              <>
                <p className="mb-0">
                Absolutely. Cash flow is an area that businesses often struggle with, especially
cannabis businesses caught under the tax burdens of IRS Section 280E. We’ll help you project cash flows based on current operations and projected changes, plan for any needed cash infusions into the business, and create strategic plans to increase cash inflows and decrease or delay cash outflows.

                </p>
              </>
            ),
          },
          {
            question:
              "Do you prepare annual budgets?",
            answer: (
              <>
                <p className="mb-0">
                Yes! We can provide just about any financial or management reporting you can think of.
                </p>
              </>
            ),
          },
          {
            question:
              "How do you determine which Key Performance Indicators (KPI) to report on?",
            answer: (
              <>
                <p className="mb-0">
                There are certain KPIs that are relevant to all businesses within the cannabis industry. So, we’ll start there and then gain an understanding of what’s unique about your business, where you’re struggling, and where you’re excelling to help formulate the balance of the KPIs you need to track for your business. Beyond that, we can help you tie these KPIs to your financial forecasts to help determine if you’re on track to meet the overall financial goals of the business.
                </p>
              </>
            ),
          },
          {
            question:
              "Which deliverables are typically included in a Fractional CFO engagement?",
            answer: (
              <>
                <p className="mb-0">
                Forecasts, budgets, budget-to-actual, KPI dashboards, product/service margin & profitability analyses, to name a few. The real magic comes from the conversations that result from these deliverables to help inform your strategic plan.
                </p>
              </>
            ),
          },
          {
            question:
              "How often will you meet with us?",
            answer: (
              <>
                <p className="mb-0">
                As often as you need! Our engagements range based on how involved you need us to be.
                </p>
              </>
            ),
          },
          {
            question:
              "Will you come to our office or are all meetings virtual?",
            answer: (
              <>
                <p className="mb-0">
                Most of our meetings will be virtual/remote. However, in certain circumstances, your Fractional CFO will be able to attend important on-site meetings with proper notice.
                </p>
              </>
            ),
          },
        
      ];

  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Cannabis CFO | Accounting for Dispensaries | Dark Horse"
        description="Cannabis Fractional CFO services include regular communication and guidance surrounding business decisions in the cannabis industry. Contact us to learn more!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Cannabis Fractional CFO Services</h1>
              <p>
              We offer financial leadership and strategy for enterprising cannabis businesses. Fractional CFO services include management-level reporting, advisory, and regular strategic meetings to drive your cannabis business forward.
              </p>
              <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
            </div>
            <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>
      <section className="mb-20 pt-00 md:mb-28">
        <div className="container">
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Fractional CFO Offerings</h2>
        
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                  <li>Financial Modeling & Forecasting </li>
                  <li>Profitability Analyses</li>
                  <li>Customized Dashboards Highlighting KPIs</li>
                  <li>Business Strategy Advisory
</li>

              </ul>
            </div>
            <div className="relative">
             <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                 <li>Cash Flow Analysis & Restructuring</li>
                 <li>Cost Allocation Consulting</li>
                 <li>Expense Management Consulting</li>
                 <li>Financial Statement Audit Support
</li>
             </ul>

            </div>
            <div className="relative">
             <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                 <li>Due Diligence Support</li>
                 <li>Internal Controls Consulting</li>
                 <li>Section 280E Consulting</li>
                 <li>Capital Procurement Consulting

</li>
             </ul>
            </div>
          </div>
          </div>
      </section>
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              <h2>Functional Financial Metrics</h2>
              <p>
              You make business decisions constantly, so you should be able to use and understand your data just as often. After we identify your Key Performance Indicators (KPIs), we develop a dashboard you can easily access in real-time. We’ll compile your data in a simplified and personalized format, designed just for you from seed to sale.
              </p>
              
            </div>
            <div className="relative order-1 md:order-1">
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} className="rounded-3xl" />
              
            </div>
          </div>
        </div>
      </section>


      


      <section className="mb-20 pt-10 md:mb-20">
        
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Come One, Come All</h2>
        <p className="max-w-[640px] m-auto">We work with all businesses in the cannabis industry—from seed to sale!</p>
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <AniLink
                fade
                to="/cannabis-industry/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.industry.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Overall Cannabis Industry</p>
                
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/cultivators-growers-manufacturers/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.cultivators.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Cultivators & Growers</p>
               
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/dispensaries-retail/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.dispensaries.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Dispensaries</p>
                
              </AniLink>
            </div>
          </div>
       
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 text-left">
            <h2>Fractional CFOs, At Your Service</h2>
            <p className="max-w-[728px]">Meet the experts who can help you transform your business into a sophisticated operation.</p>
          </header>
          {teamMembersNodes.length > 0 && (
            <div className="grid grid-cols-2 gap-y-6 gap-x-6 md:grid-cols-3 lg:gap-x-10">
              {teamMembersNodes.map((item, i) => (
                <div key={i}>
                  <button
                    data-modal-open="modal-team-members"
                    onClick={() => setSlideIndex(i)}
                    className="group text-left font-normal no-underline"
                  >
                    <div className="mb-2.5 overflow-hidden rounded-4xl md:mb-3.5">
                      <GatsbyImage
                        image={item.headshot.asset.gatsbyImageData}
                        className="over w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="text-lg font-bold text-black md:text-xl">
                      {item.name}
                    </div>
                    <div className="mb-8 text-sm font-bold uppercase tracking-wide text-primary-900/40">
                      {item.title}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <Testimonials category="cannabis" />
      <FAQs heading="Frequently Asked Questions" uniqueFaqs={faqs} />
      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-8 md:col-start-1">
        <GatsbyImage image={data.whyus.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-4 md:col-end-13">
            <h2>Why Dark Horse?</h2>
            <p>
            We work in the industry because we are passionate about the industry. We want to see your Canna-Business succeed, and we'll do everything we can to help you along the way.
            </p>
            <ButtonWithIcon
              href="/about-us/"
              text="Meet Our People"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
     
      <CallToAction />
      <ModalTeamMembers slideIndex={slideIndex} slides={teamMembersNodes} />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    horseHead: file(relativePath: { eq: "global/logo-head-black.svg" }) {
      publicURL
    }
    horseHeadOrange: file(relativePath: { eq: "global/logo-head-orange.svg" }) {
      publicURL
    }
    head: file(relativePath: { eq: "home/horse-head.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 55)
      }
    }
    whyus: file(
        relativePath: { eq: "home/Why Dark Horse_.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    rotatingWords: file(
      relativePath: { eq: "global/rotating-words-cfo-black.svg" }
    ) {
      publicURL
    }
    hero: file(
      relativePath: { eq: "home/1.0hero-cfo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    intro: file(
        relativePath: { eq: "home/2.0 Functional Financial Metrics.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
    dashboard: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/dashboard.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    cultivators: file(
        relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      dispensaries: file(
        relativePath: { eq: "home/Dispensaries.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      industry: file(
        relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    reports: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/reports.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    teamMembers: allSanityTeamMember(
        filter: {industries: {elemMatch: {}}, industriesFilter: {elemMatch: {title: {eq: "Cannabis"}}}, serviceTypes: {elemMatch: {title: {eq: "Fractional CFO"}}}}
      ) {
        edges {
          node {
            id
            name
            headshot {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 564)
                title
              }
            }
            directPhoneNumber
            emailAddress
            linkedinProfile
            hubspotID
            _rawBiography
            title
          }
        }
      }
  }
`;

export default Page;
